export function errorEvent(category, action, label) {
  return zaraz?.track('Error', { category, action, label })
}

export function purchaseEvent(cartID, totalAmount, payMethod) {
  return zaraz?.ecommerce('Order Completed', {
    order_id: cartID,
    total: totalAmount,
    payment_type: payMethod,
  })
}
